@import "theme-rsc.css";

body {
  margin: 0;
  font-family: OpenSans, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.logo {
    width: 74px;
}

.full-width {
  width: 100%;
}

.container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.spinner {
  left: 49%;
  position: fixed !important;
  top: 15%;
  z-index: 9999;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.login-container {
  height: 80vh;
}

.login-actions {
  margin-left: unset !important;
}

.mat-list-item,
.mat-menu-item {
  font-size: 14px !important;
}

.command-wrapper button {
  margin-left: 10px;
  margin-bottom: 5px;
}

.mat-dialog-title {
  margin: 0 !important;
}

.mat-column-actions {
  flex: 0 0 60px;
}



.no-records {
  padding-top: 20px;
  padding-bottom: 20px;
}

.refresh-button {
  margin-top: 8px !important;
}

.no-records .mat-icon {
  width: 130px;
  height: 130px;
  font-size: 130px;
  color: #c7c7c7;
}

p .mat-icon {
  vertical-align: middle;
}

.mat-slide-toggle.full-width {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mat-tooltip {
  font-size: 14px;
}

.fixed-fab {
  position: fixed !important;
  right: 2%;
  bottom: 3%;
}

/* Highlight on hover */
.submitted-timesheet-day:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
/* Highlight */
.submitted-timesheet-day>div:not(.mat-calendar-body-selected) {
  border-radius: 999px;
  background-color: rgba(14, 152, 5, 0.25);
}

.table-wrapper {
  overflow: auto;
  height: calc(100vh - 270px);
  max-height: 450px;
  /* padding: 10px 5px; */
}

.animate {
  animation-name: show;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.mat-button-base {
    margin: 8px 8px 8px 8px !important;
}

.large-icon {
    font-size: 50px;
    width: 50px !important;
    height: 50px !important;
}

.overlay-backdrop {
    background-color: black;
    opacity: 0.4 !important;
}

.image-overlay {
    display: block !important;
}

.image-chooser-card-container .mat-badge-content {
    font-family: 'Material Icons' !important;
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
