@font-face {
    font-family: 'OpenSans';
    font-style: italic;
    font-weight: 300;
    src: url(OpenSans-LightItalic.ttf) format('ttf'), url(OpenSans-LightItalic.woff) format('woff'), url(OpenSans-LightItalic.woff2) format('woff2') ;
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style: italic;
    font-weight: 400;
    src: url(OpenSans-Italic.ttf) format('ttf'), url(OpenSans-Italic.woff) format('woff'), url(OpenSans-Italic.woff2) format('woff2');
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style: italic;
    font-weight: 500;
    src: url(OpenSans-MediumItalic.ttf) format('ttf'), url(OpenSans-MediumItalic.woff) format('woff');
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style: italic;
    font-weight: 600;
    src: url(OpenSans-SemiBoldItalic.ttf) format('ttf'), url(OpenSans-SemiBoldItalic.woff) format('woff'), url(OpenSans-SemiBoldItalic.woff2) format('woff2');
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style: italic;
    font-weight: 700;
    src: url(OpenSans-BoldItalic.ttf) format('ttf'), url(OpenSans-BoldItalic.woff) format('woff'), url(OpenSans-BoldItalic.woff2) format('woff2');
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style: italic;
    font-weight: 800;
    src: url(OpenSans-ExtraBoldItalic.ttf) format('ttf'), url(OpenSans-ExtraBoldItalic.woff) format('woff'), url(OpenSans-ExtraBoldItalic.woff2) format('woff2');
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 300;
    src: url(OpenSans-Light.ttf) format('ttf'), url(OpenSans-Light.woff2) format('woff2'), url(OpenSans-Light.woff) format('woff');
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    src: url(OpenSans-Regular.ttf) format('ttf'), url(OpenSans-Regular.woff) format('woff'), url(OpenSans-Regular.woff2) format('woff2');
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 500;
    src: url(OpenSans-Medium.ttf) format('ttf'), url(OpenSans-Medium.woff) format('woff');
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 600;
    src: url(OpenSans-SemiBold.ttf) format('ttf'), url(OpenSans-SemiBold.woff) format('woff'), url(OpenSans-SemiBold.woff2) format('woff2');
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 700;
    src: url(OpenSans-Bold.ttf) format('ttf'), url(OpenSans-Bold.woff) format('woff'), url(OpenSans-Bold.woff2) format('woff2');
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 800;
    src: url(OpenSans-ExtraBold.ttf) format('ttf'), url(OpenSans-ExtraBold.woff) format('woff'), url(OpenSans-ExtraBold.woff2) format('woff2');
  }